import React from 'react';

import { Box, Grid } from '@core';
import {
  CurrencyPicker,
  ImageCore,
  LOADING_OPTIONS,
  LanguagePicker,
  LinkWrapper,
} from '@components';
import atlasLogo from '@images/logos/atlas-logo.svg';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

const CheckoutNavBar = ({ isLogoClickable, isCurrencySelectShown, t }) => (
  <Grid.Container data-section-id="CheckoutNavBar" pt={{ md: 16 }} pb={{ _: 40, md: 0 }}>
    <Grid.Row
      position="relative"
      px={{ _: 16, xl: 0 }}
      py={16}
      maxWidth={1200}
      zIndex={10002} //Above checkout sticky bottom section
    >
      {isLogoClickable ? (
        <Box
          position="relative"
          zIndex={10003} // Above currency and language picker container, so it would be clickable
          onClick={() => trackCustomGA4Event({ eventName: GA4_EVENTS.logoClick })}
        >
          <LinkWrapper link="/">
            <ImageCore src={atlasLogo} alt="Atlas VPN" loading={LOADING_OPTIONS.eager} />
          </LinkWrapper>
        </Box>
      ) : (
        <ImageCore src={atlasLogo} alt="Atlas VPN" loading={LOADING_OPTIONS.eager} />
      )}

      <Box
        position="absolute"
        height="100%"
        width="100%"
        top={0}
        right={0}
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isCurrencySelectShown && (
          <CurrencyPicker titleDesktop={`${t('checkout:inputFields.currency.label')}:`} />
        )}

        <LanguagePicker wrapperProps={{ mr: { md: 48, xl: 24 }, ml: { md: 24 } }} />
      </Box>
    </Grid.Row>
  </Grid.Container>
);

export default CheckoutNavBar;
